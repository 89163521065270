angular.module(config.app.name).component('procedureStart', {
    templateUrl: 'components/procedure/components/start.component.html',
    bindings: {
        setData: '&',
        getData: '&'
    },
    controller: function (procedureTools) {
        var self = this;
        self.forAnotherPerson = false;
        self.procedureType = null;
        self.$onInit = function () {
            self.procedureTypes = procedureTools.getAllProcedureTypes();
        };
        self.next = function () {
            if (self.procedureType !== null) {
                self.setData({
                    type: 'procedure-start',
                    data: {
                        procedureType: self.procedureType,
                        forAnotherPerson: self.forAnotherPerson
                    }
                });
            }
        };
    }
});